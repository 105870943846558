export interface TsAppVersion {
    version: string;
    name: string;
    description?: string;
    versionLong?: string;
    versionDate: string;
    gitCommitHash?: string;
    gitCommitDate?: string;
    gitTag?: string;
};
export const versions: TsAppVersion = {
    version: '6.0.0',
    name: '@digitaservice/app',
    versionDate: '2024-10-14T15:23:54.233Z',
    gitCommitHash: '469a944bb',
    versionLong: '6.0.0-469a944bb',
};
export default versions;
